<template>
  <div class="s-layout" :style="colorObject">
    <Loading v-if="isLoading" loading-message="載入中..." :background-color="'#ffffff'" />
    <div class="scanner-view" v-if="isDisplayingQrcodeScanner">
      <div class="scanner-view__body">
        <div class="scanner-view__title mb-3">請將 QR code 對準框線</div>
        <QRCodeScanner @onScan="handleQrCodeIsScanned" :qrbox="qrbox" ref="qrScanner" class="scanner-view__scanner" />
        <button class="scanner-view__close s-btn s-btn-outline-primary s-btn-xs mt-3" type="button"
          @click="handleCloseScannerButtonIsClicked">
          取消
        </button>
      </div>
    </div>
    <div class="s-container">
      <div class="content">
        <!-- <Header :page-title="getModuleConfig('page_title')" :page-image="getModuleConfig('page_image')"
          :page-kv-image="getModuleConfig('page_kv_image')" :header-section="getModuleConfig('header_section')"
          :meta="meta" /> -->

        <div class="custom-elanco-header">
          <span class="custom-elanco-header__text">Hi {{ deepGet(user, 'name') }}</span>
          <a :href="deepGet(event, 'config.header_logo_url', '#')">
            <img
              v-if="deepGet(event, 'config.images.header_logo')"
              :src="deepGet(event, 'config.images.header_logo')"
              class="custom-elanco-header__image ml-3"
            >
          </a>
        </div>

        <!-- page: redeem start -->
        <div v-if="page === 'redeem'">

          <img v-if="event.config.images" :src="event.config.images.redeem_confirm_kv" alt="Elanco" class="img-fluid">

          <div class="py-3 px-4">
            <!-- Branch Select -->
            <div class="card__info">
              <ShopSelection
                v-model="branch_id"
                ref="shopSelection"
                :disabled="isLoading || !branch_provider || isOverMaxRedeemCount"
                :branch-provider="branch_provider || ''"
                :show-branch-code="false"
                :placeholder="'請選擇購買地點'"
              />
            </div>

            <div class="redeem-notes mt-3">
              📌 小提醒來囉：送出後，今天就是毛小孩的第一天用藥日！
              <br>
              系統會從首次集點日期開始計算，接下來會貼心推播提醒，記得準時幫寶貝補藥喔 🐾
            </div>

            <!-- Buttons Start -->
            <div class="btn">
              <button class="s-btn s-btn-primary s-btn-xs" type="button"
                :disabled="isLoading || !branch_provider || isOverMaxRedeemCount" @click="redeem">
                確定送出
              </button>
              <button
                class="s-btn s-btn-outline-primary s-btn-xs mt-3"
                type="button"
                v-if="!isLoading && (isOverMaxRedeemCount || error_message.length > 0)"
                @click="goToCard"
              >
                {{ deepGet(event, 'config.member_center_text', '返回會員專區') }}
              </button>
              <button
                class="s-btn s-btn-outline-primary s-btn-xs mt-3"
                type="button"
                v-if="!isLoading && (isOverMaxRedeemCount || error_message.length > 0)"
                @click="openScanner"
              >
                {{ deepGet(event, 'config.re_scan_text', '重新掃描') }}
              </button>
            </div>
            <!-- Buttons End -->
          </div>
        </div>
        <!-- page: redeem End -->

        <!-- page: redeem success start -->
        <div v-if="page === 'redeem_success'" class="content__success">
          <!-- Card Start -->

          <!-- Branch Select -->
          <img v-if="event.config.images" :src="event.config.images.redeem_success" alt="Elanco" class="img-fluid">


          <!-- Card End -->
          <!-- Buttons Start -->
          <div class="py-3 px-4">

            <div class="text-center py-3 content__success__title">恭喜您獲得1顆星星</div>

            <button class="s-btn s-btn-outline-primary s-btn-xs" type="button" :disabled="isLoading" @click="goToCard">
              查看回饋詳情
            </button>
          </div>
          <!-- Buttons End -->
        </div>
        <!-- page: redeem success End -->

        <!-- page: card start-->
        <div v-if="page === 'card'" class="content__card d-flex flex-column">

          <!-- Branch Select -->
          <div class="d-flex content__card__header align-items-center py-3 px-4">
            <div class="flex-fill">
              <div class="content__card__header__title">資格星星數與等級</div>
              <div class="content__card__header__level">{{ cardConfig.level }}</div>
            </div>
            <div class="content__card__header__avatar">
              <img v-if="cardConfig.avatar" :src="require(`./images/${cardConfig.avatar}`)" alt="Avatar"
                class="img-fluid">
            </div>
          </div>

          <div class="content__card__rating py-3 px-4 d-flex justify-content-center">
            <img v-for="i in 6" :key="i"
              :src="require(`./images/card-star-${i <= redeemed_count ? 'enable' : 'disable'}.png`)" class="mx-2" />
          </div>

          <div class="px-3 py-2">
            <img v-if="cardConfig.content" :src="require(`./images/${cardConfig.content
              }`)" alt="Content" class="img-fluid">
          </div>


          <div class="content__card__dog flex-fill p-2" :style="cardBgStyle">

            <div class="position-relative">
              <img :src="require(`./images/${cardConfig.dog}`)" class="img-fluid"
                :style="{ marginTop: redeemed_count > 0 ? '0' : '-65px' }" alt="Dog" />

              <!-- Buttons Start -->
              <div class="content__card__dog__buttons px-3 py-3">
                <button class="btn btn-link my-1 mx-2" @click="handleOpenScannerButtonIsClicked">
                  <img src="./images/card-btn-3.png" class="img-fluid" />
                </button>

                <div class="d-flex justify-content-center">
                  <button class="btn btn-link my-1 mx-2" :disabled="isLoading" @click="goToCouponList">
                    <img src="./images/card-btn-1.png" class="img-fluid" />
                  </button>
                  <a class="btn btn-link my-1 mx-2" :href="deepGet(event, 'config.event_detail_url', '#')">
                    <img src="./images/card-btn-2.png" class="img-fluid" />
                  </a>
                </div>
              </div>
            </div>

          </div>
          <!-- Buttons End -->
        </div>
        <!-- page: card end-->

        <!-- page: empty message start-->
        <div v-if="page === 'empty_message'">
          <div class="card">
            <p class="alert-message">
              網址錯誤，沒有活動代碼或序號
            </p>
          </div>
          <!-- 返回上一頁 -->
          <div class="btn">
            <button class="s-btn s-btn-bg-primary s-btn-xs" type="button" @click="goBack">
              返回上一頁
            </button>
          </div>
        </div>
        <!-- page: empty message end-->
      </div>
    </div>
  </div>
</template>

<script>
// Packages
import { mapGetters } from "vuex";
import { isAfter } from "date-fns";
import deepGet from "lodash/get";
// Waltily Utils
import { themeColorFn } from "@/mixins/liff/themeColor";
import dateOptionMixin from "@/mixins/liff/dateOption";
import https from "@/apis/liff/v2/https";
// Components
// import Header from "@/components/Page/Liff/Shared/HeaderV3";
import Loading from "@/components/Page/Liff/Shared/Loading";
import ShopSelection from "@/components/Page/Liff/Branch/ShopSelection";
import QRCodeScanner from "@/components/QRCodeScanner.vue";

// Api
import redeemApi from "@/apis/liff/v2/redeem";

const cardConfigMap = [
  { level: "多重奏s狗", avatar: "card-avatar.png", content: "card-content-0.png", dog: "card-dog-0.png", bg: "#FFFFFF" },
  { level: "多重奏s狗", avatar: "card-avatar.png", content: "card-content-1.png", dog: "card-dog-1.png", bg: "#FFFFFF" },
  { level: "銅星級", avatar: "card-avatar-bronze.png", content: "card-content-2.png", dog: "card-dog-2.png", bg: "#f9ea79" },
  { level: "銅星級", avatar: "card-avatar-bronze.png", content: "card-content-3.png", dog: "card-dog-3.png", bg: "#f9ea79" },
  { level: "銀星級", avatar: "card-avatar-silver.png", content: "card-content-4.png", dog: "card-dog-4.png", bg: "#e3e1d6" },
  { level: "銀星級", avatar: "card-avatar-silver.png", content: "card-content-5.png", dog: "card-dog-5.png", bg: "#e3e1d6" },
  { level: "金星級", avatar: "card-avatar-gold.png", content: "card-content-6.png", dog: "card-dog-6.png", bg: "#ffab24" },
]

export default {
  mixins: [
    themeColorFn({ themeConfigPage: 'redeem' }),
    dateOptionMixin,
  ],
  components: {
    // Header,
    Loading,
    ShopSelection,
    QRCodeScanner,
  },
  mounted() {
    if (this.$route.query.event_code) {
      this.eventCode = this.$route.query.event_code;
    } else {
      this.$swal({
        // title: '提醒',
        text: '網址錯誤，沒有活動代碼',
        confirmButtonText: '確定',
      })
      this.page = 'empty_message';
      return;
    }

    if (this.$route.params.code) {
      this.code = this.$route.params.code;
    } else {
      this.page = 'card';
    }

    this.fetchEvent();
  },
  data() {
    return {
      isLoading: false,
      qrbox: {
        width: 200,
        height: 200,
      },
      isDisplayingQrcodeScanner: false,
      eventCode: null,
      event: {
        config: {},
      },
      code: null,
      page: 'redeem', // redeem, redeem_success, card
      branch_id: null,
      redeemed_count: 0,
      branch_provider: "",
      error_message: [],
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
    }),
    ...mapGetters("liffAuth", ["user"]),
    // 判斷 Event 是否在活動時間內
    isEventTime() {
      if (this.event) {
        const now = new Date();
        return isAfter(now, new Date(this.event.start_at)) && isAfter(new Date(this.event.end_at), now);
      }
      return false;
    },
    cardConfig() {
      if (this.redeemed_count > 6) {
        return cardConfigMap[6];
      }
      return cardConfigMap[this.redeemed_count];
    },
    cardBgStyle() {
      return {
        background: `linear-gradient(to bottom, #93bf65 0%, ${this.cardConfig.bg} 5%, ${this.cardConfig.bg} 100%)`,
      };
    },
    maxRedeemCount() {
      return deepGet(this.event, 'config.max_redeem_count', null);
    },
    isOverMaxRedeemCount() {
      return this.maxRedeemCount !== null && this.redeemed_count >= this.maxRedeemCount;
    },
  },

  methods: {
    deepGet,
    isValidUrl(string) {
      try {
        new URL(string);
        return true;
      } catch (_) {
        return false;
      }
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"]("liff_redeem", key);
    },
    async fetchEvent() {
      try {
        this.isLoading = true;

        const eventRes = await redeemApi.getRedeemEvent({
          event_code: this.eventCode,
        });

        this.event = eventRes.data.data.event;
        this.error_message = eventRes.data.data.event.config.error_message ?? [];

        const response = await https.get(`${this.$store.state.liffGeneral.orgCode}/liff/elanco/init`, {
          params: {
            event_code: this.eventCode,
            code: this.code,
          }
        })

        this.branch_provider = this.event.config.branch_provider ?? null;

        // 如果 this.branch_provider 不是 waltily.branch. 開頭，則幫忙補上，反之則不要
        if (!this.branch_provider.startsWith('waltily.branch')) {
          this.branch_provider = `waltily.branch:${this.branch_provider}`;
        }

        this.redeemed_count = response.data.data.redeemed_count;

        if (this.page === 'redeem') {
          this.$nextTick(() => {
            this.$refs.shopSelection.fetchBranchesOptions();
          });

          if (this.isOverMaxRedeemCount) {
            const errorMessageConfig = this.findMappedError('REDEEM_LIMIT_REACHED');
            this.$swal({
              // title: deepGet(errorMessageConfig, 'title', '提醒'),
              text: deepGet(errorMessageConfig, 'message', '設定錯誤'),
              confirmButtonText: '確定',
            })
          }
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 400) {
          const errorMessageConfig = this.findMappedError(error.response.data.message);
          this.$swal({
            // title: deepGet(errorMessageConfig, 'title', '提醒'),
            text: deepGet(errorMessageConfig, 'message', '設定錯誤'),
            confirmButtonText: '確定',
          })
        } else {
          this.$swal({
            // title: '提醒',
            text: '活動資訊讀取失敗',
            confirmButtonText: '確定',
          })
        }
      } finally {
        this.isLoading = false;
      }
    },
    async redeem() {
      try {
        // 檢查必要資料
        this.isLoading = true;

        if (!this.branch_id) {
          this.$swal({
            // title: '提醒',
            text: '請選擇分店',
            confirmButtonText: '確定',
          })
          return;
        }

        if (!this.eventCode || !this.code) {
          this.$swal({
            // title: '提醒',
            text: '網址錯誤，沒有活動代碼或序號',
            confirmButtonText: '確定',
          })
          return;
        }

        let response = await https.post(`${this.$store.state.liffGeneral.orgCode}/liff/elanco/redeem`, {
          code: this.code,
          event_code: this.eventCode,
          branch_id: this.branch_id,
        });

        if (response.data.message === 'REDEEM_SUCCESS') {
          //this.$swal('提醒', '序號使用成功', 'success');
          this.page = 'redeem_success';
          this.redeemed_count = response.data.redeemed_count;
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 400) {
          const errorMessageConfig = this.findMappedError(error.response.data.message);
          this.$swal({
            // title: deepGet(errorMessageConfig, 'title', '提醒'),
            text: deepGet(errorMessageConfig, 'message', '設定錯誤'),
            confirmButtonText: '確定',
          })
        } else {
          this.$swal({
            // title: '提醒',
            text: '發生錯誤，請稍後再試',
            confirmButtonText: '確定',
          })
        }
      } finally {
        this.isLoading = false;
      }
    },
    goToCard() {
      this.$router.push({ name: 'LiffProjElanco', query: { event_code: this.eventCode } }, () => {
        window.location.reload();
      });
    },
    findMappedError(code) {
      const errorMessageConfig = this.error_message ?? [];
      return errorMessageConfig.find(item => item.code === code);
    },
    displayErrorWithRescanButton(title, message) {
      this.$swal({
        // title: title,
        text: message,
        confirmButtonText: '確定',
      })
    },
    openScanner() {
      this.isDisplayingQrcodeScanner = true
    },
    goBack() {
      this.$router.go(-1);
    },
    goToCouponList() {
      this.$router.push({ name: 'LiffCouponListMy' });
    },
    async handleQrCodeIsScanned(decodedText) {
      if (!this.isValidUrl(decodedText)) {
        this.$swal({
          // title: '提醒',
          text: '掃描結果不是有效的 URL',
          confirmButtonText: '確定',
        })
        return;
      }
      window.location = decodedText;
    },
    handleOpenScannerButtonIsClicked() {
      this.isDisplayingQrcodeScanner = true
    },
    handleCloseScannerButtonIsClicked() {
      this.$refs.qrScanner.stopScanner()
      this.isDisplayingQrcodeScanner = false
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-elanco-header {
  padding: 16px;
  background-color: #93bf65;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__image {
    height: 40px;
  }
}

.scanner-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;

  &__body {
    width: 500px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 32px;
  }

  &__title {
    font-size: 1.25rem;
    color: #555;
    text-align: center;
  }

  &__scanner {
    width: 100%;
    aspect-ratio: 1 / 1;
    background-color: #f5f5f5;
    border-radius: 8px;
    overflow: hidden;
  }
}

.redeem-notes {
  font-size: 14px;
  color: #333;
}
</style>

<style lang="scss">
@import "../../../../assets/css/liff/main.css";
@import "../../../../assets/css/liff/liff_init_theme_config.css";
@import '../../../../assets/scss/shared/components/_fields.scss';

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
}

.s-container {
  min-height: 100vh;
  position: relative;
  max-width: 768px;
  margin: auto;
}

.form {
  padding: 0 5px;

  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
  --liff-button-color: #93bf65;
  --liff-primary_text_color: #93bf65;
  --s-primary: #93bf65;


}

.content {
  height: 100vh;
  overflow-y: auto;
  margin: 0;
  padding-bottom: 24px;
  color: var(--liff-primary_text_color);

  &__success {
    &__title {
      font-size: 24px;
      font-weight: 600;
    }
  }

  &__card {

    padding-bottom: 0;

    background-color: var(--liff-primary_text_color);

    &__header {

      color: white;

      &__title {
        font-size: 20px;
        font-weight: 600;
      }

      &__level {
        font-size: 36px;
        font-weight: 600;
      }

      &__avatar {
        img {
          width: 110px;
          height: 110px;
        }
      }
    }

    &__rating {
      img {
        width: 40px;
        height: 40px;
      }
    }


    &__dog {
      &__buttons {
        position: absolute;
        bottom: 0;
      }
    }





  }

}

.logo {
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: 300px;
  max-height: 100px;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}


.card {
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px;
  margin-top: 12px;

  &__title {
    color: #2c2c2e;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 8px;
  }

  &__divider {
    border-bottom: 2px dashed #e5e5ea;
  }

  &__info {
    color: #636366;
    line-height: 24px;
    font-size: 16px;
    padding-top: 12px;

    b {
      color: #2c2c2e;
      font-weight: 500;
    }
  }
}


.redeem {
  background-size: cover;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;

  &__code {
    color: #2c2c2e;
    font-size: 19px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }

  &__input {
    background: #ffffff;
    border: 1px solid #636366;
    border-radius: 10px !important;
    border: 1px solid #636366;
    padding: 15px 12px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 32px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }
  }
}

.btn {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;

  // button {
  //   background: var(--liff-button-color);
  //   color: var(--liff-button-text-color);
  // }
}

.s-btn-primary {
  background-color: var(--liff-button-color) !important;
}

.s-btn-outline-primary {
  border-width: 3px !important;
}

.gift__image {
  width: 100%;
  object-fit: contain;
}

.loading-spinner {
  order: 4;
}


.custom-checkbox {
  padding-left: 1.5rem;
  margin-right: 1rem;

  .custom-control-input {
    &+label {
      position: relative;
      cursor: pointer;
      padding: 0;
      color: var(--liff-primary_text_color);
      font-size: 16px;
    }

    &+label:before {
      content: "";
      margin-right: 8px;
      display: inline-block;
      vertical-align: text-top;
      width: 18px;
      height: 18px;
      border: 1px solid var(--liff-button-color) !important;
      border-radius: 4px;
      background: white;
      left: -1.5rem;
    }

    &+label::after {
      left: -1.5rem;
    }

    &:focus+label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked+label:before,
    &:active+label:before {
      background: var(--liff-button-color) !important;
    }

    &:disabled+label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled+label:before {
      box-shadow: none;
      background: var(--s-gray-light);
    }
  }
}

.fixed-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.field__label {
  font-size: 16px !important;
}

.field__desc {
  font-size: 14px !important;
}

.alert-message {
  text-align: center;
  color: var(--s-danger);
  font-size: 16px;
  padding: 12px;
  margin: 0;
}
</style>
